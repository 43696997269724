import React, { useEffect ,useState} from 'react'
import { CardElevation } from '@findep/mf-landings-core'
import PropTypes from 'prop-types'
import CarouselImg from '../img/CarouselImg.compilable'
import { imageCss, TextHeader, TextBox } from '../../styles/lo-sentimos'
import { Grid } from '@material-ui/core';

function LoSentimosCard({ data, companyName }) {

    const [ errorNip, setErrorNip] = useState(false)
    useEffect(() => {
        sessionStorage.clear()
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores)
        setErrorNip(urlParams.has('nip-error'));
    }, [])

    return (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6}>
                <br /> <br />

                <TextHeader>{errorNip ?'El NIP fue erróneo en 3 ocasiones, para reclamar el dinero es nescesario comenzar un proceso nuevo' :data.textHeader}</TextHeader>
                <br />
                <TextBox>
                    {data.textBox}
                    <br /><br />
                    {data.textBoxTwo}
                    <br /><br />
                    {data.textBoxThree}
                    <br /><br />
                </TextBox>
                <br />
                <Grid container justify="center">
                    <Grid item>
                        {/* <ButtonDegrade
                            onClick={
                                () => navigate(`/encuesta-salida/`)
                            }
                            textButton="CONTESTAR ENCUESTA"
                        /> */}
                    </Grid>
                </Grid>
                <br />
                <CardElevation fullWidth noPadding>
                    <div css={imageCss}>
                        <CarouselImg filename="2.svg" company={companyName} />
                    </div>
                </CardElevation>

                <br /><br />&nbsp;&nbsp;&nbsp;
            </Grid>
        </Grid>
    )
}

LoSentimosCard.propTypes = {
    data: PropTypes.object.isRequired,
    companyName: PropTypes.string.isRequired
}

export default LoSentimosCard