import React from 'react'
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'

function LoSentimos({ companyName }) {

    const dataInfo = {
        "textHeader": "Agradecemos tu interés en tramitar un crédito con nosotros, por el momento no podemos ofrecerte el monto que solicitas.",
        "textBox": "Te sugerimos primero que revises que los datos que ingresaste en la solicitud son correctos.",
        "textBoxTwo": "Recuerda que es muy importante mantener tus finanzas saludables,  para tener un mejor historial crediticio que te permitan ser candidato a un préstamo.",
        "textBoxThree": "¡No te desanimes! Te invitamos a que regreses más adelante con nosotros para evaluar nuevamente tu solicitud.",
    }

    return (
        <Container justify="center">
            <LoSentimosCard data={dataInfo} companyName={companyName} />
        </Container>

    )
}



export default LoSentimos

