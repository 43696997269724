import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const imageCss = css`
        width: 80%;
        margin: auto;
    `

export const TextHeader = styled.div`
        color: #e66728;
        text-align: left;
        font-weight: bold;
        font-size: 20px;
    `
export const TextBox = styled.div`
        color: black;
        text-align: left;
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: normal;
    `